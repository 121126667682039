@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata&family=Montserrat:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #1f1f26;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.single-page{
    margin: 0 auto;
    width:90vw;
    max-width: 1200px;
}

.about{
    line-height: 200%;
    color: #fdfdfe;
    margin-top: 15vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}


.section_title{
    color: #bb6bd0;
    font-size: clamp(3.5rem, 5vw, 5rem);
}

.form-container{
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
}
.form{

    justify-content: center;
    align-items: center;
}
.form-item{
    margin-left: 50px;
}
.row{
    display: flex;
    margin: 2% 0;

}
.row-label{
    flex: 2 1;
    text-align: right;
    font-size: clamp(1rem, 4vw, 2rem);
    color:#fdfdfe;
}
.row-input{
    flex: 5 1;
    margin-left: 1%;
    border-radius: 5px;
}
.submit-btn{
    justify-self: end;
    font-size: clamp(1rem, 4vw, 2rem);
    background-color: #ba6acf;
    color: #2c012c;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #2c012c;
    text-shadow: -1px 1px #282c34;
    border-radius: 10px;
}


.projects {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
}





.title {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.title_text {
    align-items: center;
    color: black;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    justify-content: space-between;
    width: 100%;
}

@-webkit-keyframes ani_sub_text {
    0%{opacity: 0; -webkit-transform: translateY(5vh); transform: translateY(5vh)}
    50%{opacity: 0; -webkit-transform: translateY(5vh); transform: translateY(5vh)}
    100%{opacity: 1;-webkit-transform: translateY(0);transform: translateY(0)}
}

@keyframes ani_sub_text {
    0%{opacity: 0; -webkit-transform: translateY(5vh); transform: translateY(5vh)}
    50%{opacity: 0; -webkit-transform: translateY(5vh); transform: translateY(5vh)}
    100%{opacity: 1;-webkit-transform: translateY(0);transform: translateY(0)}
}
.title_sub_text{
    -webkit-animation: ani_sub_text 2s normal ease-in-out;
            animation: ani_sub_text 2s normal ease-in-out;
    display: flex;
    flex-direction: row;
    font-family: 'Inconsolata', monospace;
    font-size: clamp(2rem, 5vw, 5rem);
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}


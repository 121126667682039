.form-container{
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
}
.form{

    justify-content: center;
    align-items: center;
}
.form-item{
    margin-left: 50px;
}
.row{
    display: flex;
    margin: 2% 0;

}
.row-label{
    flex: 2;
    text-align: right;
    font-size: clamp(1rem, 4vw, 2rem);
    color:#fdfdfe;
}
.row-input{
    flex: 5;
    margin-left: 1%;
    border-radius: 5px;
}
.submit-btn{
    justify-self: end;
    font-size: clamp(1rem, 4vw, 2rem);
    background-color: #ba6acf;
    color: #2c012c;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #2c012c;
    text-shadow: -1px 1px #282c34;
    border-radius: 10px;
}
